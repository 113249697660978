<template>
  <div>
    <title>SIM INVENTORY ~ DATA PART COMPONENT</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Part Component Data List
        <br />
        <h4>The following is a list of master part component data lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div v-if="addData" class="col-md-4">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Part Component Data List</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1"
                    >Part Code / Part Number
                  </label>
                  <input
                    type="text"
                    v-model="isidata.kodepart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Part Name</label>
                  <input
                    type="text"
                    v-model="isidata.namapart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="text"
                    v-model="isidata.qtyjml"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notespart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <div v-if="isFrmeditData" class="col-md-4">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Part Component Data List</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1"
                    >Part Code / Part Number
                  </label>
                  <input
                    type="text"
                    v-model="isidata.kodepart"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.idpart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Part Name</label>
                  <input
                    type="text"
                    v-model="isidata.namapart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="text"
                    v-model="isidata.qtyjml"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notespart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <button @click="awaldepan()" class="btn btn-success">
                  <i class="fa fa-angle-double-left"></i>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-8">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Master Part Component Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Part Code / Part Number"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Part Code</th>
                      <th>Part Name</th>
                      <th>Qty</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>
                        {{ index + 1 + (page - 1) * pageSize }}
                      </td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.part_no }}</td>
                      <td>{{ classdata.name }}</td>
                      <td>{{ classdata.qty }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>
                        <button
                          @click="EditData(classdata.id)"
                          title="Edit Part"
                          class="btn btn-info"
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        &nbsp;
                        <button
                          title="Delete part"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Part Code</th>
                      <th>Part Name</th>
                      <th>Qty</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="6" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "klasifikasiartikel",
  components: {
    VPagination
  },
  data() {
    return {
      isidata: {
        kodepart: "",
        namapart: "",
        qtyjml: "",
        notespart: "",
        idpart: ""
      },
      addData: true,
      isFrmeditData: false,
      isShow: false,
      isExist: true,
      loading: false,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    awaldepan() {
      this.addData = true;
      this.isFrmeditData = false;
      this.isidata.kodepart = "";
      this.isidata.notespart = "";
      this.isidata.namapart = "";
      this.isidata.qtyjml = "";
      this.isidata.idpart = "";
      this.fetchData();
    },
    EditData(id) {
      this.isFrmeditData = true;
      this.addData = false;
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/part_component/getpart_componentbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "part_component/getpart_componentbyid?id=" + id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          this.isidata.idpart = resp.data.data.id;
          this.isidata.kodepart = resp.data.data.part_no;
          this.isidata.namapart = resp.data.data.name;
          this.isidata.qtyjml = resp.data.data.qty;
          this.isidata.notespart = resp.data.data.notes;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var kodepart = this.isidata.kodepart;
      var namapart = this.isidata.namapart;
      var notes = this.isidata.notespart;
      var qty = this.isidata.qtyjml;
      var idx = this.isidata.idpart;
      if (kodepart == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (namapart == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notes == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qty == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          part_no: this.isidata.kodepart,
          name: this.isidata.namapart,
          qty: this.isidata.qtyjml,
          notes: this.isidata.notespart,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/part_component/updatepart_component/" + idx;
        const urlAPIUpdate =
          this.$apiurl + "part_component/updatepart_component/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
              this.addData = true;
              this.isidata.kodepart = "";
              this.isidata.notespart = "";
              this.isidata.namapart = "";
              this.isidata.qtyjml = "";
              this.isidata.idpart = "";
              this.isFrmeditData = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/part_component/getalldatapart_component?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "part_component/getalldatapart_component?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if(resp.data.data.length == 0) {
            this.isExist = false
          } else {
            this.isExist = true
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    searchdata() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        this.$router.push({ name: "part" });
        this.loading = true;
        this.fetchData();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/part_component/getalldatapart_component?cari=" + this.namesearch+"&length="+this.pageSize;
        const urlAPIget =
          this.$apiurl +
          "part_component/getalldatapart_component?cari=" +
          this.namesearch +
          "&length=" +
          this.pageSize;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            if(resp.data.data.length == 0) {
              this.isExist = false
            } else {
              this.isExist = true
              this.list_paged = resp.data.data;
              this.count = resp.data.datatotalcount;
            }
            this.loading = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data
            })
          });
      }
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/part_component/hapuspart_component/" + id;
          const urlAPIbrgdel =
            this.$apiurl + "part_component/hapuspart_component/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              })
            });
        } else {
          this.loading = false;
        }
      });
    },
    async saveData() {
      this.loading = true;
      var kode = this.isidata.kodepart;
      var nama = this.isidata.namapart;
      var qty = this.isidata.qtyjml;
      var notes = this.isidata.notespart;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qty == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notes == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          part_no: this.isidata.kodepart,
          name: this.isidata.namapart,
          qty: this.isidata.qtyjml,
          notes: this.isidata.notespart,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPISaveData = "http://26.183.23.191/inventory/backend/inventory/api/part_component/savepart_component";
        const urlAPISaveData =
          this.$apiurl + "part_component/savepart_component";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.kodepart = "";
              this.isidata.namapart = "";
              this.isidata.qtyjml = "";
              this.isidata.notespart = "";
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
